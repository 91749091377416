<template>
  <div class="toast-container mt-3 start-50 translate-middle-x">
    <transition-group name="fade">
      <div
        v-for="toast in toasts"
        :key="toast.id"
        class="toast d-block"
        :class="[backgroundClass(toast.level), 'text'+textColorClass(toast.level)]"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="d-flex">
          <div class="toast-body">
            {{ toast.message }}
          </div>
          <button
            type="button"
            class="btn-close me-2 m-auto"
            :class="['btn-close'+textColorClass(toast.level)]"
            data-bs-dismiss="toast"
            aria-label="Close"
            @click="toastStore.closeToast(toast.id)"
          />
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useToastStore } from "@/stores/toast.store";

const toastStore = useToastStore();
const toasts = ref(toastStore.toasts);

watch(() => toastStore.toasts, (newToasts) => {
  toasts.value = newToasts;
});

const backgroundClass = (level) => {
  // Define your mapping of levels to background classes
  const levelClasses = {
    success: "bg-success",
    warning: "bg-warning ",
    error: "bg-danger",
  };

  // Return the corresponding class for the given level
  return levelClasses[level] || "";
};

const textColorClass = (level) => {
  // Set text color to white for levels other than info
  return level && level !== "info" ? "-white" : "";
};
</script>

<style scoped>
.bg-success,
.bg-warning,
.bg-danger {
  .toast-body {
    font-weight: bold;
  }
}

.toast {
  width: max-content;
  max-width: 440px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
