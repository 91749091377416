import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";
import { useAuthStore } from "@/stores/auth.store";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/login",
      name: "login",
      component: LoginView,
      meta: {
        title: "Login",
      },
    },
    {
      path: "/",
      name: "weekly-calendar",
      component: () => import("../views/calendars/WeeklyCalendarView.vue"),
      meta: {
        title: "Weekly Calendar",
      },
    },
    {
      path: "/daily-calendar",
      name: "daily-calendar",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/calendars/DailyCalendarView.vue"),
      meta: {
        title: "Daily Calendar",
      },
    },
    {
      path: "/classes",
      name: "classes",
      component: () => import("../views/ClassesView.vue"),
      meta: {
        title: "Classes",
      },
    },
    {
      path: "/teachers",
      name: "teachers",
      component: () => import("../views/TeachersView.vue"),
      meta: {
        title: "Teachers",
      },
    },
    {
      path: "/students",
      name: "students",
      component: () => import("../views/StudentsView.vue"),
      meta: {
        title: "Students",
      },
    },
    {
      path: "/student-registrations",
      name: "studentRegistrations",
      component: () => import("../views/StudentRegistrationsView.vue"),
      meta: {
        title: "Student Registrations",
      },
    },
    {
      path: "/companies",
      name: "companies",
      component: () => import("../views/CompaniesView.vue"),
      meta: {
        title: "Companies",
      },
    },
    {
      path: "/logout",
      name: "logout",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => useAuthStore().logout(),
      meta: {
        title: "Logout",
      },
    },
  ],
});

router.beforeEach(async (to) => {
  document.title = to.meta.title ? <string>to.meta.title : "NikaTeacher - Schedule";

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();

  if (authRequired && !auth.tokens) {
    auth.returnUrl = to.fullPath;
    return "/login";
  }
});

export default router;
