<template>
  <div class="login container">
    <div class="row login-wrapper">
      <div class="col d-flex flex-column align-items-center">
        <div class="title">
          <h2 class="mb-5 text-center">
            Welcome to<br>
            <img src="@/assets/img/nt-logo.png" alt="" height="77">
          </h2>
        </div>

        <form @submit="submit">
          <div>
            <label for="email" class="form-label">E-mail</label>
            <div class="input-group mb-4">
              <input
                id="email"
                v-model="email"
                class="form-control"
                placeholder="e-mail"
              >
            </div>

            <label for="password" class="form-label">Password</label>
            <div class="input-group mb-5">
              <input
                id="password"
                v-model="password"
                class="form-control"
                placeholder="password"
                type="password"
              >
            </div>
          </div>

          <button
            type="button"
            class="btn btn-primary w-100"
            :disabled="isAuthenticating"
            @click="submit"
          >
            <span
              v-if="isAuthenticating"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span v-else class="font-weight-bold"> Login </span>
          </button>
        </form>
        <div
          v-if="inputErrors['non_field_errors']"
          class="alert alert-danger p-1 mt-3 mb-0"
          role="alert"
        >
          {{ inputErrors['non_field_errors'] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useAuthStore } from "@/stores/auth.store";
import { IInputErrors } from "@/types/input";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoginView",
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      inputErrors: {} as IInputErrors,
    };
  },
  computed: {
    isAuthenticating() {
      return this.authStore.isAuthenticating;
    },
    isPasswordValid() {
      if (!this.submitted) {
        return true;
      }
      return this.password.length >= 4;
    },
    passwordInvalid() {
      if (this.password.length === 0) {
        return "Password is a required field.";
      }
      return "Password must be at least 6 characters long.";
    },
    isEmailValid() {
      if (!this.submitted) {
        return true;
      }
      return this.email.length > 0;
    },
    emailInvalid() {
      return "E-mail is a required field.";
    },
  },
  created() {
    // Reset login status
    // this.$store.dispatch('auth/logout');
  },
  methods: {
    submit() {
      this.submitted = true;

      if (this.isEmailValid && this.isPasswordValid) {
        const { email, password } = this;

        return this.authStore.login(email, password).catch((error) => {
          this.authStore.loading.authenticating = false;
          if (error?.response && error.response?.status === 401) {
            this.inputErrors["non_field_errors"] = "Login failed. Please check your credentials.";
          }
        });
      }
    },
  },
});
</script>

<style scoped>
.login.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .login-wrapper {
    margin-top: -50px;
  }

  .alert.alert-danger {
    max-width: 600px;
  }
}
</style>
