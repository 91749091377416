<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <div class="container-fluid">
      <RouterLink to="/" class="navbar-brand">
        <img src="@/assets/img/nt-logo.png" alt="" height="37">
      </RouterLink>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="showMenu = !showMenu"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <div
        id="navbarSupportedContent"
        class="collapse navbar-collapse"
        :class="{show: showMenu}"
      >
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li
            v-for="link in links"
            class="nav-item"
            @click="closeMenu"
          >
            <RouterLink :to="link.path" class="nav-link">
              {{ link.name }}
            </RouterLink>
          </li>
        </ul>

        <RouterLink v-slot="{navigate}" to="/logout" custom>
          <button class="btn btn-danger" type="button" @click="navigate">
            Logout
          </button>
        </RouterLink>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { RouterLink } from "vue-router";

export default {
  components: { RouterLink },
  data() {
    return {
      showMenu: false,
      links: [
        {
          name: "Weekly Calendar",
          path: "/",
        },
        {
          name: "Classes",
          path: "/classes",
        },
        {
          name: "Teachers",
          path: "/teachers",
        },
        {
          name: "Students",
          path: "/students",
        },
        {
          name: "Student Registrations",
          path: "/student-registrations",
        },
        {
          name: "Companies",
          path: "/companies",
        },
      ],
    };
  },
  methods: {
    closeMenu() {
      this.showMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>

nav {
  padding: 0.2rem 0;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,.15);
  min-height: $menu-height-desktop;

  .nav-link.active {
    font-weight: 500;
  }

  .navbar-collapse.collapse {
    display: block;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.2s ease, opacity 0.2s ease;

    @include lg {
      max-height: initial;
      opacity: 1;
    }

    &.show {
      max-height: 1000px;
      opacity: 1;
      transition: max-height 0.4s ease, opacity 0.4s ease;
    }
  }
}
</style>
