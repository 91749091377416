<script setup lang="ts">
import { RouterView } from "vue-router";
import { useAuthStore } from "@/stores/auth.store";
import Navbar from "@/components/navbar/Navbar.vue";
import BaseModal from "@/components/modals/BaseModal.vue";
import Toast from "@/components/ui/toast/Toast.vue";
import FullScreenLoadingSpinner from "@/components/ui/spinner/FullScreenLoadingSpinner.vue";

const authStore = useAuthStore();

// Load teachers to the store to make them accessible as resources in calendars.
if (authStore.userLoggedIn) {
  authStore.loadInitialData();
}
</script>

<template>
  <Navbar v-if="authStore.userLoggedIn" />

  <Toast />

  <Teleport to="body">
    <FullScreenLoadingSpinner  v-if="authStore.loading.initial_data" />
  </Teleport>
  <div v-if="!authStore.loading.initial_data" class="main">
    <RouterView />
  </div>

  <BaseModal />

</template>

<style lang="scss" scoped>
.main {
  padding: 1rem 0.5rem 2rem 0.5rem;
  overflow: auto;
  background: #fcfcfc;
  height: 100%;

  @include xs {
    padding: 0.5rem;
  }
  @include lg {
    padding: 1.5rem 2rem 1rem 2rem;
  }
}
</style>
