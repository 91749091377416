import { defineStore } from "pinia";

import router from "@/router";
import { api } from "@/api";
import { useMappingsStore } from "@/stores/mappings.store";
import { useUserStore } from "@/stores/user.store";
import { useCalendarStore } from "@/stores/calendar.store";


export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    // initialize state from local storage to enable student to stay logged in
    tokens: JSON.parse(<string>localStorage.getItem("tokens")),
    returnUrl: "",
    loading: {
      authenticating: false,
      initial_data: false,
    },
  }),
  getters: {
    userStore() {
      return useUserStore();
    },
    calendarStore() {
      return useCalendarStore();
    },
    mappingsStore() {
      return useMappingsStore();
    },
    userLoggedIn(state) {
      return state.tokens != null;
    },
    isAuthenticating(state) {
      return state.loading.authenticating;
    },
  },
  actions: {
    async loadInitialData() {
      this.loading.initial_data = true;
      await Promise.all([
        this.userStore.getTeachers(),
        this.userStore.getAllCompanies(),
        this.userStore.getCurrentTeacherInfo(),
        this.calendarStore.getAllSchoolClasses(),
        // Load mappings and extra data
        this.mappingsStore.getMappings(),
      ]);

      this.loading.initial_data = false;
    },
    async login(email: string, password: string) {
      this.loading.authenticating = true;
      const response = await api.authApi.login(email, password);

      this.tokens = response.data;
      // store student details and jwt in local storage to keep student logged in between page refreshes
      localStorage.setItem("tokens", JSON.stringify(this.tokens));

      this.loading.authenticating = false;

      await this.loadInitialData();

      // redirect to previous url or default to home page
      await router.push(this.returnUrl || "/");
    },
    logout() {
      this.tokens = null;
      localStorage.removeItem("tokens");
      router.push("/login");
    },
  },
});
